//
//
//
//
//
//
//
//
//
//
//

import Vue from 'vue';
import Card from '@/views/components/homeCard/index.vue';
import ProductCart from '../components/productCard/index.vue';

export default Vue.extend({
  name: 'products',
  components: {
    Card, ProductCart,
  },
  data() {
    return {
      isFromLogin: false,
    };
  },
});
